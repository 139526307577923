import { Button, Grid, Link, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

const Template = ({ children }) => {
  return (
    <Grid container direction={"row"}>
      <Grid item lg={3} sm={0}></Grid>
      <Grid item lg={1} style={{ height: "100vh", backgroundColor: "black" }}>
        <Grid style={{ position: "absolute", bottom: 10 }} marginLeft={1}>
          <Grid>
            <Link
              fontFamily={"Montserrat"}
              style={{ color: "white" }}
              href="/about"
            >
              about
            </Link>
          </Grid>
          <Grid>
            <Link
              fontFamily={"Montserrat"}
              style={{ color: "white" }}
              href="/editions"
            >
              editions
            </Link>
          </Grid>
          <Grid>
            <Link
              fontFamily={"Montserrat"}
              style={{ color: "white" }}
              href="/advertize"
            >
              advertize
            </Link>
          </Grid>

          <Grid>
            <Link
              fontFamily={"Montserrat"}
              style={{ color: "white" }}
              href="/privacy"
            >
              privacy
            </Link>
          </Grid>

          <Grid>
            <Link
              fontFamily={"Montserrat"}
              style={{ color: "white" }}
              href="/jobs"
            >
              jobs
            </Link>
          </Grid>


        </Grid>
      </Grid>

      {children}

      <Grid item lg={3}></Grid>
    </Grid>
  );
};

export default Template;
