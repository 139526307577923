import React from 'react'
import Template from '../components/Template'
import IndianDispatch from './IndianDispatch'

const Homepage = () => {
  return (
    <Template children={<IndianDispatch />} />
  )
}

export default Homepage