import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles"
import { createTheme } from "@mui/material";
// @ts-ignore
import Nerd from "./fonts/bbt.TTF";
import IndianDispatch from "./screens/IndianDispatch";
import Homepage from "./screens/Homepage";

const rootAdminRouter = createBrowserRouter([
  {
    path: "/",
    element: <Homepage />
  },
  {
    path: "/editions",
    element: <Homepage />
  },
  {
    path: "/privacy",
    element: <Homepage />
  },
  {
    path: "/about",
    element: <Homepage />
  }
]);

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Nerd';
          font-size: 10;
          src: local('Nerd'), local('Nerd-Regular'), url(${Nerd}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

function App() {
  return (
      <ThemeProvider theme={theme}>
        <RouterProvider router={rootAdminRouter} />
      </ThemeProvider>
  );
}

export default App;