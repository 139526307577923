import { Button, Grid, Link, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";

const BACKEND = "https://indiandispatch.in/api";
const LOCAL = "http://localhost:3004/api";

function IndianDispatch() {
  const [email, setEmail] = useState<string>("");
  const [success, setMessage] = useState<boolean | null>(null);

  const onSubscribe = async () => {
    const req = await fetch(BACKEND + "/subscribe", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    });

    if (req.status === 200) {
      setMessage(true);
    } else {
      setMessage(false);
    }
  };
  
  return (
    <Grid
      item
      container
      lg={5}
      sm={10}
      height={"100%"}
      style={{ height: "100vh", backgroundColor: "#e3a857" }}
      direction={"column"}
    >
      {/* Top header */}
      <Grid
        container
        justifyContent={"space-between"}
        marginTop={1}
        direction={"row"}
      >
        <Grid marginLeft={2}>
          <Typography fontFamily={"Montserrat"} fontSize={15}>
            ₹ 0
          </Typography>
        </Grid>
        <Grid style={{ fontSize: 30, fontFamily: "Parisienne" }}>The</Grid>
        <Grid marginRight={2}>
          <Typography fontFamily={"Montserrat"} fontSize={15}>
            {new Date().toISOString().split("T")[0]}
          </Typography>
        </Grid>
      </Grid>
      {/* Second Row  */}
      <Grid container justifyContent={"space-between"} direction={"row"}>
        <Grid
          style={{
            fontSize: "4.5vw",
            marginLeft: "2vw",
            fontFamily: "Montserrat",
          }}
        >
          Indian Dispatch
        </Grid>
      </Grid>
      {/* Information Row  */}
      <Grid container justifyContent={"space-between"} direction={"row"}>
        <Grid lg={2}></Grid>

        <Grid lg={8}>
          <Typography textAlign={"center"}>is a biweekly newsletter</Typography>
          <Typography textAlign={"center"}>
            {" "}
            providing latest news on tech, open-source and other cool stuff
            around internet
          </Typography>
        </Grid>

        <Grid lg={2}></Grid>
      </Grid>

      {/* Newsletter Row  */}
      <Grid
        style={{ marginTop: 10 }}
        container
        justifyContent={"space-between"}
        direction={"row"}
      >
        <Grid lg={2}></Grid>
        <Grid lg={8} direction={"row"} container>
          <Grid item lg={8} marginX={1}>
            <TextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              placeholder="Email"
              size="small"
            ></TextField>
          </Grid>
          <Grid item lg={2}>
            {success === null || success === false ? (
              <Button
                variant="outlined"
                onClick={() => {
                  onSubscribe();
                }}
                color="secondary"
              >
                Subscribe
              </Button>
            ) : (
              <Button
                variant="outlined"
                onClick={() => {
                  onSubscribe();
                }}
                color="success"
              >
                Subscribe
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid lg={2}></Grid>
      </Grid>

      {/* Social Media Row  */}
      <Grid container justifyContent={"space-between"} direction={"row"}>
        <Grid lg={1}></Grid>
        <Grid>
          <FacebookIcon />
          *
          <TwitterIcon />
          *
          <InstagramIcon />
          *
          <EmailIcon />
        </Grid>
        <Grid lg={1}></Grid>
      </Grid>
      <Grid></Grid>
      {/* Footer  */}
      <Grid
        style={{ position: "absolute", bottom: 0, left: 550, width: 400 }}
        container
        justifyContent={"space-between"}
        direction={"row"}
      >
        <Grid lg={2}></Grid>
        <Typography fontSize={20} fontFamily={"Parisienne"}>
          Priyadarshan Singh
        </Typography>
        <Grid lg={1} sm={0}></Grid>
      </Grid>
    </Grid>
  );
}

export default IndianDispatch;
